import axios from 'axios'

export function addProcedimiento (procedimiento) {
  return axios.post('/procedimientos', procedimiento)
  .then(response => {
    return response.data
  })
}

export function getAllProcedimientos () {
  return axios.get('/procedimientos')
  .then(response => {
    return response.data
  })
}

export function updateProcedimiento (id, procedimiento) {
  return axios.put('/procedimientos/' + id, procedimiento)
  .then(response => {
      return response.data
    })
}

export function getProcedimiento (id) {
  return axios.get('/procedimientos/' + id)
  .then(response => {
      return response.data
    })
}

export function deleteProcedimiento (id) {
  return axios.delete('/procedimientos/' + id)
  .then(response => {
      return response.data
    })
}

export function uploadFile (payload) {
  return axios.post('/procedimientos/file',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then(response => {
    return response.data
  })
}

export function deleteFile (id) {
  return axios.delete('/procedimientos/file/' + id)
  .then(response => {
      return response.data
    })
}

export function downloadFile (id) {
  return axios({
    method: 'get',
    url: '/procedimientos/file/' + id,
    responseType: 'blob',
  })
  .then(response => {
      return response
    })
}

export default {
  addProcedimiento,
  getAllProcedimientos,
  updateProcedimiento,
  getProcedimiento,
  deleteProcedimiento,
  uploadFile,
  deleteFile,
  downloadFile,
}
