import axios from 'axios'

export function getAllEstadoExpediente () {
  return axios.get('/maestros/estado_expediente')
  .then(response => {
    return response.data
  })
}

export function updateEstadoExpediente (id, estadoexpediente) {
  return axios.put('/maestros/estado_expediente/' + id, estadoexpediente)
  .then(response => {
      return response.data
    })
}

export function addEstadoExpediente (estadoexpediente) {
  return axios.post('/maestros/estado_expediente', estadoexpediente)
  .then(response => {
    return response.data
  })
}

export function deleteEstadoExpediente (id) {
  return axios.delete('/maestros/estado_expediente/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneEstadoExpediente (id) {
    return axios.get('/maestros/estado_expediente/' + id)
    .then(response => {
        return response.data
      })
}

export function getLogsExpediente (id) {
  return axios.get('/maestros/estado_expediente/audit/' + id)
  .then(response => {
      return response.data
    })
}

export function ordenarEstados (estadosExpedientes) {
  return axios.post('/maestros/estado_expediente/ordenar/estados', estadosExpedientes)
  .then(response => {
      return response.data
    })
}

export default {
  getAllEstadoExpediente,
  updateEstadoExpediente,
  addEstadoExpediente,
  deleteEstadoExpediente,
  getOneEstadoExpediente,
  getLogsExpediente,
  ordenarEstados,
}
