import axios from 'axios'

export function addExpedientes (expediente) {
  return axios.post('/expedientes', expediente)
  .then(response => {
    return response.data
  })
}

export function getAllExpedientes () {
  return axios.get('/expedientes')
  .then(response => {
    return response.data
  })
}

export function getAllExpedientesAbiertos () {
  return axios.get('/expedientes?estado=abiertos')
  .then(response => {
    return response.data
  })
}

export function getAllExpedientesCerradosPorMes () {
  return axios.get('/expedientes/cerrados')
  .then(response => {
    return response.data
  })
}

export function getAllExpedientesUsuario () {
  return axios.get('/expedientes?usuario=true')
  .then(response => {
    return response.data
  })
}
export function getAllExpedientesConPendientes () {
  return axios.get('/expedientes?estado=pendientes')
  .then(response => {
    return response.data
  })
}

export function getPendientes () {
  return axios.get('/expedientes/pendientes/usuarios')
  .then(response => {
    return response.data
  })
}

export function getAllExpedientesUsuarioAbiertos () {
  return axios.get('/expedientes/usuario/abiertos')
  .then(response => {
    return response.data
  })
}

export function updateExpedientes (id, expediente) {
  return axios.put('/expedientes/' + id, expediente)
  .then(response => {
      return response.data
    })
}

export function updateEstadoExpedientes (id, expediente) {
  expediente.email = localStorage.getItem('user')
  return axios.put('/expedientes/estado/' + id, expediente)
  .then(response => {
      return response.data
    })
}

export function getTimeLine (id) {
  return axios.get('/expedientes/historial/' + id)
  .then(response => {
    return response.data
  })
}

export function getRequerimientos (id) {
  return axios.get('/expedientes/usuarios/' + id)
  .then(response => {
    return response.data
  })
}

export function downloadFile (id) {
  return axios({
    method: 'get',
    url: '/expedientes/file/' + id,
    responseType: 'blob',
  })
  .then(response => {
      return response
    })
}

export function addCliente (cliente) {
  return axios.post('/expedientes/usuarios', cliente)
  .then(response => {
    return response.data
  })
}

export function deleteUsuariosExpedientes (id) {
  return axios.delete('/expedientes/usuarios/' + id)
  .then(response => {
      return response.data
    })
}

export function isFinalizado (id) {
  return axios.get('/expedientes/finalizado/' + id)
  .then(response => {
    return response.data
  })
}

export function uploadFile (payload) {
  return axios.post('/expedientes/file',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then(response => {
    return response.data
  })
}

export function deleteFile (id) {
  return axios.delete('/expedientes/file/' + id)
  .then(response => {
    return response.data
  })
}

export function getAllExpedientesOficial () {
  return axios.get('/expedientes?oficial=true')
  .then(response => {
    return response.data
  })
}

export function getExpedientesById (id) {
  return axios.get('/expedientes/' + id)
  .then(response => {
    return response.data
  })
}

export default {
  addExpedientes,
  getAllExpedientes,
  updateExpedientes,
  getTimeLine,
  getRequerimientos,
  downloadFile,
  addCliente,
  deleteUsuariosExpedientes,
  updateEstadoExpedientes,
  isFinalizado,
  uploadFile,
  deleteFile,
  getAllExpedientesUsuario,
  getPendientes,
  getAllExpedientesUsuarioAbiertos,
  getAllExpedientesConPendientes,
  getAllExpedientesAbiertos,
  getAllExpedientesCerradosPorMes,
  getAllExpedientesOficial,
  getExpedientesById,
}
