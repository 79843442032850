import axios from 'axios'

export function addClientes (cliente) {
  return axios.post('/clientes', cliente)
  .then(response => {
    return response.data
  })
}

export function getAllClientes () {
  return axios.get('/clientes')
  .then(response => {
    return response.data
  })
}

export function getClienteByUsername (username) {
  return axios.get('/clientesbyusername?email=' + username)
  .then(response => {
    return response.data
  })
}

export function updateClientes (id, cliente) {
  return axios.put('/clientes/' + id, cliente)
  .then(response => {
      return response.data
    })
}

export function deleteTel (id) {
  return axios.delete('/clientes/telefono/' + id)
  .then(response => {
      return response.data
    })
}

export function addTelClientes (payload) {
  return axios.post('/clientes/telefono', payload)
  .then(response => {
    return response.data
  })
}

export function addEmailClientes (payload) {
  return axios.post('/clientes/email', payload)
  .then(response => {
    return response.data
  })
}

export function deleteEmail (id) {
  return axios.delete('/clientes/email/' + id)
  .then(response => {
      return response.data
    })
}

export function deleteTelConyuge (id) {
  return axios.delete('/clientes/conyuge/telefono/' + id)
  .then(response => {
      return response.data
    })
}

export function addTelConyuge (payload) {
  return axios.post('/clientes/conyuge/telefono', payload)
  .then(response => {
    return response.data
  })
}

export function addEmailConyuge (payload) {
  return axios.post('/clientes/conyuge/email', payload)
  .then(response => {
    return response.data
  })
}

export function deleteEmailConyuge (id) {
  return axios.delete('/clientes/conyuge/email/' + id)
  .then(response => {
      return response.data
    })
}

export function uploadFile (payload) {
  return axios.post('/clientes/file',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then(response => {
    return response.data
  })
}

export function deleteFile (id) {
  return axios.delete('/clientes/file/' + id)
  .then(response => {
      return response.data
    })
}

export function downloadFile (id) {
  return axios({
    method: 'get',
    url: '/clientes/file/' + id,
    responseType: 'blob',
  })
  .then(response => {
      return response
    })
}

export function loadSociedades (id) {
  return axios.get('/clientes/' + id + '/sociedades')
  .then(response => {
      return response.data
    })
}

export function deleteClientesSociedad (cliente, sociedad) {
  return axios.delete('/clientes/' + cliente + '/sociedad/' + sociedad)
  .then(response => {
      return response.data
    })
}

export function deleteAsociado (asociado) {
  return axios.delete('/clientes/sociedad/' + asociado)
  .then(response => {
      return response.data
    })
}

export function addAsociado (asociado) {
  return axios.post('/clientes/sociedad', asociado)
  .then(response => {
    return response.data
  })
}

export function getAllClientesOnlyName () {
  return axios.get('/clientes?onlyname=1')
  .then(response => {
    return response.data
  })
}

export default {
  addClientes,
  getAllClientes,
  updateClientes,
  deleteTel,
  addTelClientes,
  addEmailClientes,
  deleteEmail,
  deleteTelConyuge,
  addTelConyuge,
  addEmailConyuge,
  deleteEmailConyuge,
  uploadFile,
  deleteFile,
  downloadFile,
  loadSociedades,
  deleteClientesSociedad,
  deleteAsociado,
  addAsociado,
  getClienteByUsername,
  getAllClientesOnlyName,
}
