import axios from 'axios'

export function addEmpleados (empleado) {
  empleado.notificador = localStorage.getItem('user')
  return axios.post('/empleados', empleado)
  .then(response => {
    return response.data
  })
}

export function deleteEmpleado (id) {
  return axios.delete('/empleados/', id)
  .then(response => {
    return response.data
  })
}

export function getAllEmpleados () {
  return axios.get('/empleados')
  .then(response => {
    return response.data
  })
}

export function getEmpleado (id) {
  return axios.get('/empleados/' + id)
  .then(response => {
    return response.data
  })
}

export function getEmpleadoByUser (id) {
  return axios.get('/empleados/user/' + id)
  .then(response => {
    return response.data
  })
}

export function getClienteByUsername (username) {
  return axios.get('/empleadosbyusername?email=' + username)
  .then(response => {
    return response.data
  })
}

export function updateEmpleados (id, empleado) {
  empleado.notificador = localStorage.getItem('user')
  return axios.put('/empleados/' + id, empleado)
  .then(response => {
      return response.data
    })
}

export function deleteTel (id) {
  return axios.delete('/empleados/telefono/' + id)
  .then(response => {
      return response.data
    })
}

export function addTelEmpleados (tlfns) {
  return axios.post('/empleados/telefono', tlfns)
  .then(response => {
    return response.data
  })
}

export function addEmailEmpleados (emails) {
  return axios.post('/empleados/email', emails)
  .then(response => {
    return response.data
  })
}

export function deleteEmail (id) {
  return axios.delete('/empleados/email/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  addEmpleados,
  deleteEmpleado,
  getAllEmpleados,
  getEmpleado,
  getClienteByUsername,
  updateEmpleados,
  deleteTel,
  addTelEmpleados,
  addEmailEmpleados,
  deleteEmail,
  getEmpleadoByUser,
}
